import { styled } from '@mui/system';

export const UsageAdjustWrapper = styled('div')`
    margin: 44px 0;

    h5 {
        font-size: 20px;
        margin: 0 0 12px;
    }

    p {
        font-size: 15px;
        color: #666;
        margin: 0;
    }
`

export const UsageAdjustChart = styled('div')`
    margin-top: 24px;
`

export const UsageAdjustRow = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`

export const UsageAdjustItem = styled('div')`
    margin: 8px 12px;
    flex: 0 0 25%;
`

export const GetUsageEntry = styled('div')`
    background-color: #F5F7F7;
    border-radius: 20px;
    padding: 24px 32px;

    p {
        margin-top: 0;
    }
`